import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Transformation } from "cloudinary-react"

import { Image } from "../components/Core"

function ReviewRow(props) {
  let url = props.url
  if (url.charAt(0) !== "/") url = "/" + url
  return (
    <div className="review-row">
      <div className="columns review-row-content">
        <div className="column is-4"></div>
        <div className="column is-2 review-photo-and-caption">
          <Image
            publicId={props.photo}
            responsiveUseBreakpoints="true"
            className="review-row-photo">
            <Transformation
              width="200"
              height="200"
              gravity="face"
              radius="max"
              crop="thumb"
            />
          </Image>
        </div>
        <div className="column mobile-row-p">
          <div className="review-row-heading">
            <div className="mobile-row-heading">
              <h6 style={{ display: "inline" }}>
                {props.name} Reviews {props.gender === "F" ? "Her" : "His"}{" "}
                {props.nameSingular == "Wisdom Tooth Removal"
                  ? "Wisdom Teeth Removal"
                  : props.nameSingular}
                &nbsp;Procedure
              </h6>
              <br className="mobile-break" />
              <Link className="review-row-link" to={url.toLowerCase()}>
                Watch Review
              </Link>
            </div>
          </div>

          <p>"{props.quote}"</p>
        </div>
        <div className="column is-4"></div>
      </div>
    </div>
  )
}

ReviewRow.propTypes = {
  minimal: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string
}

export default ReviewRow
